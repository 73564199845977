<template>
	<TitleBar :isChat="true" :title="title" :isBlank="true"
		@goBack="goBack"
		@clearConversation="clearConversation"
		@download="onDownload"
	>{{ desc }}</TitleBar>
	<div class="conversation scrollable">
		<ChatBox v-for="item in list" ref="chatBox"
			:key="item[2]" :item="item" :userImg="userImg" :avatarImg="item[1].image"
			@updated="updatePosition" @chooseAIHint="onInput"
		/>
	</div>
	<Inputter ref="inputter" @onInput="onInput" />
	<div class="bottom"></div>
</template>

<style scoped>
.titlebar {
	position: absolute;
	z-index: 1;
	top: 0px;
	width: 100%;
}
.conversation {
	position: absolute;
	z-index: 0;
	top: 64px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 75px;
}
.bottom {
	position: absolute;
	z-index: 1;
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 20px;
	background-color: rgb(49, 51, 57);
	background: linear-gradient(to top, rgba(49, 51, 57, 1.0) 0%, rgba(49, 51, 57, 0.75) 50%, rgba(49, 51, 57, 0.0));
}
</style>

<script>
// @ is an alias to /src
import TitleBar from '@/components/titleBar.vue';
import ChatBox from '@/components/chat.vue';
import Inputter from '@/components/inputter.vue';

const SaveHistory = true;
const AvatarImg = {
	"h1": '/avatar/h1.png',
	"h2": '/avatar/h2.png',
	"s1": '/avatar/s1.png',
	"s2": '/avatar/s2.png',
};

const DuplicateConversation = conversation => {
	var list = conversation.map(item => {
		return [...item];
	});
	return list;
};
const simpleParse = line => {
	line = line.replace(/\*\*([\w\W]+?)\*\*/g, (m, inner) => {
		return '[bold:]' + inner + '[:bold]';
	}).replace(/__([\w\W]+?)__/g, (m, inner) => {
		return '[bold:]' + inner + '[:bold]';
	}).replace(/\*([\w\W]+?)\*/g, (m, inner) => {
		return '[italic:]' + inner + '[:italic]';
	}).replace(/_([\w\W]+?)_/g, (m, inner) => {
		return '[italic:]' + inner + '[:italic]';
	}).replace(/\!\[([\w\W]*?)\]\s*\(([\w\W]+?)\)/g, (m, title, url) => {
		return '{img:' + url + '}';
	}).replace(/\[([\w\W]*?)\]\s*\(([\w\W]+?)\)/g, (m, title, url) => {
		return '<a target="_blank" href="' + url + '">' + title + '</a>';
	});

	return line;
};

var current;

export default {
	name: 'ChatRoom',
	components: {
		TitleBar,
		ChatBox,
		Inputter
	},
	props: ['roomID'],
	data () {
		return {
			title: '数字聊天室',
			desc: '',
			list: [],
			userImg: '',
			avatars: [],
			rid: 0,
			ego: '',
			soul: '',
		}
	},
	async mounted () {
		var rid = this.roomID * 1;
		if (isNaN(rid)) rid = this.roomID;
		console.log('RoomID: ' + rid);
		var room = localStorage.get('ChatRoomList', null);
		if (!!room) {
			room = room.filter(r => r.id === rid)[0];
		}
		if (!!room) {
			document.title = (room.avatars.length + 1) + "人聊天室";
			this.desc = room.intro;
			this.rid = rid;
		}
		else {
			document.title = "随机聊天室";
			this.desc = '暂无设置';
			this.rid = -1;
		}
		this.title = document.title;
		document.title = 'AIVerse - ' + document.title;

		current = this;
		this.$refs.inputter.inactive();

		// 监听后台传回的数据
		global.onAvatarReply = msg => {
			if (current !== this) return;
			msg.forEach(async reply => {
				console.log(reply);
				if (!!reply.extra.model.match(/claude/i)) {
					if (!!reply.content) reply.content = convertClaudeChinese(reply.content);
					if (!!reply.reply) reply.reply = convertClaudeChinese(reply.reply);
					if (!!reply.action) reply.action = convertClaudeChinese(reply.action);
					if (!!reply.emotion) reply.emotion = convertClaudeChinese(reply.emotion);
				}
				reply.extra = reply.extra || {};
				var name = window._avatarList.filter(ava => ava.url === reply.extra.ai)[0];
				if (!!name) reply.extra.name = name.name;
				reply.image = 'background-image: url("' + AvatarImg[reply.extra.ai] + '")'
				this.addChat(1, reply);
				await wait();
			});
		};

		// 获取自身唯一ID
		var ego = await DataCenter.get(DBNames.record, 'self', 'ego');
		if (!ego) {
			ego = newID(16);
			await DataCenter.set(DBNames.record, 'self', 'ego', ego);
		}
		this.ego = ego;

		// 获取当前个人信息的更新记录
		var me = await DataCenter.get(DBNames.record, 'self', 'me');
		if (!me) {
			me = {
				logo: '/avatar/user.png',
				update: 1,
			};
		}
		if (!me.update) me.update = 1;

		// 设置自身头像
		var color = [];
		color[0] = Math.round(Math.random() * 130) + 70;
		color[1] = Math.round(Math.random() * 130) + 70;
		color[2] = Math.round(Math.random() * 130) + 70;
		if (!!me.logo.match(/^(ht|f)tps?/)) {
			this.userImg = 'background-color: rgb(' + color.join(',') + ');background-image: url("' + me.logo + '")';
		}
		else {
			this.userImg = 'background-color: rgb(' + color.join(',') + ');background-image: url("' + staticHost + me.logo + '")';
		}

		// 读取聊天记录
		var conversation = await DataCenter.get(DBNames.avatar, 'history', 'chatroom-' + this.roomID);
		if (!conversation) {
			conversation = {};
			conversation.time = Date.now();
			conversation.history = [];
			conversation.roomID = this.roomID;
		}
		this.conversation = conversation;
		this.list = DuplicateConversation(this.conversation.history);

		// 获取Avatar们的信息
		this.avatars.splice(0);
		window._avatarList.forEach(ava => {
			if (!room.avatars.includes(ava.url)) {
				return;
			}
			this.avatars.push(ava);

			var url = ava.image;
			if (!url) {
				url = staticHost + AvatarImg.h1;
			}
			else if (!url.match(/^(ht|f)tps?/i)) {
				if (url.indexOf('/') === 0) url = staticHost + url;
				else url = staticHost + '/avatar/' + url;
			}
			AvatarImg[ava.url] = url;
		});

		this.$refs.inputter.active();
		this.$refs.inputter.focusInput();

		await wait(100);
		this.updatePosition();
	},
	unmounted () {
		sendRequest('/chatRoom', {
			event: 'append',
			ego: this.ego,
			rid: this.rid,
			content: [{ content: 'stop', target: 'EVERYONE' }],
		});
		if (current === this) current = null;
	},
	methods: {
		goBack () {
			this.$router.push({ path: '/' });
		},
		onInput (content) {
			content = this.parseInput(content);
			if (!content) return;
			content.forEach(ctx => this.addChat(0, ctx));
			this.appendChat(content);
		},
		parseInput (text) {
			var messages = [];

			text = text.replace(/^\s*|\s*$/gi, '');
			if (!text.match(/^TO[:：]/)) {
				text = 'to: everyone\n' + text;
			}

			text = ('\n' + text).split(/[\n\r]+TO[:：]/i);
			text.forEach(ctx => {
				var actions = [], emotion, environment, target;

				// 解析说话对象
				ctx = ctx.replace(/^([^\n\r]*)[\n\r]+/gi, (m, tgt) => {
					target = target || tgt.replace(/^\s*|\s*$/gi, '');
					return '';
				});
				ctx = ctx.replace(/^\s*|\s*$/gi, '');
				if (!ctx) return;
				target = this.avatars.filter(ava => ava.name === target);
				if (target.length === 0) target = 'EVERYONE';
				else target = target[0].name;
				target = target || 'EVERYONE';

				// 解析场所
				ctx = ctx.replace(/[\n\r]*\((场所|environment)\s*[:：]\s*([\w\W]+?)\s*\)[\n\r]*|[\n\r]*（(场所|environment)\s*[:：]\s*([\w\W]+?)\s*）[\n\r]*/gi, (m, u1, env1, u2, env2) => {
					environment = environment || env1 || env2;
					return '\n';
				});
				// 解析动作
				ctx = ctx.replace(/[\n\r]*\((动作|action)\s*[:：]\s*([\w\W]+?)\s*\)[\n\r]*|[\n\r]*（(动作|action)\s*[:：]\s*([\w\W]+?)\s*）[\n\r]*/gi, (m, u1, action1, u2, action2) => {
					actions.push(action1 || action2);
					return '\n';
				});
				// 解析情绪
				ctx = ctx.replace(/[\n\r]*\((表情|神态|情绪|expressions?|demeanors?|moods?|emotions?)\s*[:：]\s*([\w\W]+?)\s*\)[\n\r]*|[\n\r]*（(表情|神态|情绪|expressions?|demeanors?|moods?|emotions?)\s*[:：]\s*([\w\W]+?)\s*）[\n\r]*/gi, (m, u1, emotion1, u2, emotion2) => {
					emotion = emotion || emotion1 || emotion2;
					return '\n';
				});
				ctx = ctx.replace(/^\s*|\s*$/g, '');
				
				var input = { content: ctx, target };
				if (actions.length > 0) {
					input.action = actions.join('\n');
				}
				if (!!emotion) {
					input.emotion = emotion;
				}
				if (!!environment) {
					input.environment = environment;
				}

				messages.push(input);
			});
			return messages;
		},
		async appendChat (text) {
			this.$refs.inputter.reset();
			this.$refs.inputter.focusInput();

			await sendRequest('/chatRoom', {
				event: 'append',
				ego: this.ego,
				rid: this.rid,
				content: text,
			});

			this.updatePosition();
		},
		addChat (type, content, saveHistory=SaveHistory) {
			var now = Date.now();
			var id = newID(16);
			var item = [type, content, id];
			this.list.push(item);
			this.conversation.history.push(item);
			this.conversation.time = now;
			if (saveHistory) {
				DataCenter.set(DBNames.avatar, 'history', 'chatroom-' + this.roomID, this.conversation);
			}
			return id;
		},
		updatePosition () {
			if (!this.$refs.chatBox || !this.$refs.chatBox.length) return;
			this.$refs.chatBox.forEach(box => {
				if (box.$el._isRunningHint) {
					box.$el.parentElement.appendChild(box.$el);
				}
			});
			var last = this.$refs.chatBox[this.$refs.chatBox.length - 1];
			last.$el.scrollIntoView();
		},
		async clearConversation () {
			console.log('Clear Conversation');

			var success;
			try {
				success = await sendRequest('/chatRoom', {
					event: 'reset',
					rid: this.rid
				});
				if (!success) {
					notify({
						title: "聊天记录本就为空",
						duration: 5000,
						type: "warn"
					});
					return;
				}
			}
			catch (err) {
				console.error(err);
				notify({
					title: "聊天记录清空失败",
					message: "请稍后重试，或者联系管理员。",
					duration: 5000,
					type: "failed"
				});
				return;
			}

			this.conversation.history.splice(0);
			this.conversation.time = Date.now();
			this.list = [];
			if (SaveHistory) {
				await DataCenter.set(DBNames.avatar, 'history', 'chatroom-' + this.roomID, this.conversation);
			}

			notify({
				title: "聊天记录已清空",
				message: "本数字分身已被清空记忆，您可以重新开始新的聊天。",
				duration: 5000,
				type: "success"
			});
		},
		async onDownload () {
			var name = this.title.replace(/\s*[\(（][\w\W]+[\)）]\s*/gi, '');

			var inner = ['[title:]History of the conversation with ' + name + '[:title]'];
			var markdown = ["#\tHistory of the conversation with " + name];
			var filename = getTimeString(this.conversation.time);
			markdown.push('#\tAbout this ChatRoom\n\n' + this.desc);
			inner.push('[center:]{block:start|w200}About this ChatRoom{block:end}[:center]\n\n' + this.desc);
			markdown.push('\n-----\n');
			inner.push('-----');
			filename = this.title + '-' + filename.replace(/ /g, '-').replace(/\//g, '') + '.hst';

			var inSide = '';
			this.conversation.history.forEach(item => {
				let ctx = '', inn = [];
				if (item[0] === 0) {
					if (!!item[1].content.match(/^(\s*(安静|quite|停|打住|stop|pause|继续|continue)[!！]*\s*)+$/i)) return;
					let str;
					if (inSide !== 'human') {
						markdown.push('##\tMe');
						inner.push('[right:][big:][bold:]Me[:bold][:big][:right]');
					}
					str = item[2] * 1;
					if (!isNaN(str) && str !== 0) {
						str = getTimeString(item[2]);
						ctx = '(' + str + ')\n';
						inn.push('[right:][italic:](' + str + ')[:italic][:right]');
					}
					str = '_（对' + (item[1].target === 'EVERYONE' ? '所有人' : item[1].target) + '说：）_\n';
					str = str + item[1].content.replace(/^\s+|\s+$/g, '').replace(/\n{3,}/g, '\n\n').replace(/^\s*|\s*$/g, '');
					if (!!item[1].emotion) {
						str = str + '\n_（情绪：' + item[1].emotion + '）'
					}
					if (!!item[1].action) {
						str = str + '\n_（动作：' + item[1].action + '）'
					}
					ctx = ctx + str;
					str = str.split('\n').map(l => '[right:]' + simpleParse(l) + '[:right]').join('\n');
					inn.push(str);
					inSide = 'human';
				}
				else if (item[0] === 1) {
					let str;
					if (inSide !== item[1].sender) {
						markdown.push('##\t' + item[1].sender);
						inner.push('[big:][bold:]' + item[1].sender + '[:bold][:big]');
					}
					str = item[2] * 1;
					if (!isNaN(str) && str !== 0) {
						str = getTimeString(item[2]);
						ctx = '(' + str + ')\n';
						inn.push('[italic:](' + str + ')[:italic]');
					}
					str = '_（对' + (item[1].target === 'EVERYONE' ? '所有人' : item[1].target) + '说：）_\n';
					str = str + item[1].content.replace(/^\s+|\s+$/g, '').replace(/\n{3,}/g, '\n\n').replace(/^\s*|\s*$/g, '');
					if (!!item[1].emotion) {
						str = str + '\n_（情绪：' + item[1].emotion + '）'
					}
					if (!!item[1].action) {
						str = str + '\n_（动作：' + item[1].action + '）'
					}
					ctx = ctx + str;
					str = str.split('\n').map(l => simpleParse(l)).join('\n');
					inn.push(str);
					inSide = item[1].sender;
				}
				if (!!ctx) {
					markdown.push(ctx);
					inner.push(inn.join('\n'));
				}
			});
			markdown = markdown.join('\n\n');
			inner = inner.join('\n\n');
			inner = inner.split('\n').map(l => {
				if (!!l) return l;
				return '{block:start|h20}{block:end}';
			}).join('\n');

			var blob = new Blob([markdown], { type: 'text/plain' });
			var link = URL.createObjectURL(blob);
			var downloader = newEle('a');
			downloader.setAttribute('href', link);
			downloader.setAttribute('download', 'chatHistory.md');
			downloader.click();

			if (isExperiment) {
				await DataCenter.set(DBNames.record, 'avatar', filename, inner);
				notify({
					title: "聊天记录已经保存到本地",
					message: "你也可以在console中/SAIDAO/History/目录下查看边缘端记录。",
					duration: 5000,
					type: "success"
				});
			}
			else {
				notify({
					title: "聊天记录已经保存到本地",
					duration: 3000,
					type: "success"
				});
			}
		},
	}
};
</script>